import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  ChangeEvent,
} from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import WaitIndicator, { WaitIndicatorRef } from "../../atoms/WaitIndicator";
import RoundedButton from "../../atoms/formElements/RoundedButton";
import useAppData from "../../../hooks/useAppData";
import {
  getPhysicianGroup,
  getSites,
  updateUsers,
  sendEmailForNewChampion,
  getUserInfoFromStorage,
  SendEmailForNewSite,
} from "../../../helpers/api-util";
import RadioButtonGroup from "../../atoms/formElements/RadioButtonGroup";
import AdminContext from "../../../store/admin-context";
import LabelTextField from "../../atoms/formElements/LabelTextField";
import TypeAheadList, {
  TypeAheadListRef,
} from "../../atoms/formElements/TypeAheadList";
import SiteForm from "../../common/SiteForm";

import TypeAheadUserList, {
  TypeAheadUserListRef,
} from "../../atoms/formElements/TypeAheadUserList";
import NewUserForm from "../../common/NewUserForm";
export interface ManageChampionsFormDialogProps {
  onsuccess: any;
  onDataRefresh: () => void;
  userStatus: string;
}
export interface ManageChampionsFormDialogRef {
  openDialog: () => void;
  onSuccessRefresh: () => void;
}

const ManageChampionsFormDialog = forwardRef<
  ManageChampionsFormDialogRef,
  ManageChampionsFormDialogProps
>((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const waitIndicatorLoaderRef = useRef<WaitIndicatorRef>(null);
  const [refreshData, setRefreshData] = useState(false);
  const [selectedRedioValue, setSelectedRedioValue] = React.useState({
    id: "",
    value: "",
  });
  const [PhysicianAssigndisplay, setPhysicianAssigndisplay] =
    React.useState("none");
  const [SitesAssigndisplay, setSitesAssigndisplay] = useState("none");
  const [physicianGroupId, setPhysicianGroupId] = useState("");
  const [Physiciandisplay, setPhysiciandisplay] = useState("none");
  const [PhysicianSearchQuery, setPhysicianSearchQuery] = useState("");
  const [physicianDataFiltered, setPhysicianDataFiltered] = useState<any[]>([]);
  const [userId, setUserId] = React.useState("");
  // Define separate state variables for Autocomplete values
  const [SearchQuery, setSearchQuery] = useState("");
  const adminContext = React.useContext(AdminContext);
  const [siteFormDisplay, setSiteFormDisplay] = useState("none");
  const [newChampionFormDisplay, setNewChampionFormDisplay] = useState("none");
  const typeAheadListRef = useRef<TypeAheadListRef>(null);
  const typeAheadUserListRef = useRef<TypeAheadUserListRef>(null);

  const sitePhysicianGroup = [
    { Name: "Assign to all sites in Physician Group", Id: "1" },
    { Name: "Assign to site", Id: "2" },
  ];
  const [formData, setFormData] = useState({
    siteId: "",
    siteName: "",
    isEDPartOfLargeGroup: "",
    physicianGroupName: "",
    country: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zipCode: "",
  });

  const [newChampionFormData, setNewChampionFormData] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
  });
  function onSuccessRefresh() {
    try {
      setRefreshData(!refreshData);
    } catch (ex) {
      // console.log(ex);
    }
  }
  useImperativeHandle(ref, () => ({
    openDialog,
    onSuccessRefresh,
  }));
  const appSitesData = useAppData(async () => {
    return await getSites();
  }, refreshData);

  const appPhysicianGroupData = useAppData(async () => {
    return await getPhysicianGroup();
  }, refreshData);

  const handleClose = () => {
    waitIndicatorLoaderRef.current?.show();
    resetForm();
    resetSiteForm();
    resetNewChampionForm();
    waitIndicatorLoaderRef.current?.hide();
  };

  const openDialog = () => {
    setOpen(true);
  };

  const OnSubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      waitIndicatorLoaderRef.current?.show();
      if (formData.siteId === "notFound" || userId === "notFound") {
        sendEmailOnSubmit();
      } else {
        let myObject: any;
        if (physicianGroupId) {
          myObject = {
            physicianGroupId: physicianGroupId,
          };
        } else if (formData.siteId) {
          myObject = {
            siteId: formData.siteId,
          };
        }

        if (myObject != null) {
          let res = await updateUsers(myObject, userId, "champion");
          if (res.status === 200) {
            waitIndicatorLoaderRef.current?.hide();
            setOpen(false);
            resetForm();
            resetSiteForm();
            resetNewChampionForm();
            handleActionResponse(
              res,
              "Role assigned successfully!",
              "Champion is already assigned to the site(s)."
            );
            props.onsuccess();
          } else {
            waitIndicatorLoaderRef.current?.hide();
            resetForm();
            resetSiteForm();
            resetNewChampionForm();
            handleActionResponse(
              res,
              "Role assigned successfully!",
              "Error assigning role!"
            );
          }
        } //not filled any details and click on submit
        else {
          waitIndicatorLoaderRef.current?.hide();
          resetForm();
          resetSiteForm();
        }
      }
    } catch (ex) {
      resetForm();
      resetSiteForm();
      waitIndicatorLoaderRef.current?.hide();
    }
  };

  const resetForm = () => {
    setOpen(false);
    setUserId("");
    setPhysicianGroupId("");
    setPhysicianSearchQuery("");
    setPhysiciandisplay("none");
    setSelectedRedioValue({ id: "", value: "" });
    setSitesAssigndisplay("none");
    setPhysicianAssigndisplay("none");
    setSearchQuery("");
    setNewChampionFormDisplay("none");
    resetNameSearchQuery();
  };

  function isFormValid() {
    if (
      validateName() &&
      validateSiteOrPhysician() &&
      validateNewChampionForm()
    ) {
      return true;
    }
    return false;
  }
  const validateSite = () => {
    return formData.siteId !== "";
  };
  const validateNewChampionForm = () => {
    if (userId === "notFound")
      return (
        newChampionFormData.firstName.trim() !== "" &&
        newChampionFormData.lastName.trim() !== "" &&
        newChampionFormData.emailAddress.trim() !== ""
      );
    else return true;
  };
  const validateSiteDetails = () => {
    return formData?.siteName.trim() !== "" && validateIsEPDPartOflargeGroup();
  };
  const resetSearchQuery = () => {
    // Call the resetSearchQuery function in TypeAheadList
    typeAheadListRef?.current?.resetSearchQuery();
  };

  const resetNameSearchQuery = () => {
    // Call the resetSearchQuery function in TypeAheadList
    typeAheadUserListRef?.current?.resetSearchQuery();
  };
  // const resetNameSearchQuery = () => {
  //   // Call the resetNameSearchQuery function in TypeAheadList
  //   typeAheadListNameRef?.current?.resetSearchQuery();
  // }

  const validateIsEPDPartOflargeGroup = () => {
    if (formData?.isEDPartOfLargeGroup) {
      if (formData?.isEDPartOfLargeGroup === "Yes") {
        return formData?.physicianGroupName.trim() !== "";
      } else if (formData?.isEDPartOfLargeGroup === "No") {
        return true;
      }
    }
    return false;
  };
  const setSiteValueHandler = (e: any) => {
    if (e.id === "notFound") {
      setSiteFormDisplay("block");
      setFormData((values: any) => ({ ...values, siteId: e.id, siteName: "" }));
    } else {
      setSiteFormDisplay("none");
      setFormData((values: any) => ({
        ...values,
        siteId: e.id,
        siteName: e.name,
      }));
    }
  };

  const resetSiteForm = () => {
    setFormData((values: any) => ({
      ...values,
      siteId: "",
      siteName: "",
      isEDPartOfLargeGroup: "",
      physicianGroupName: "",
      country: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipCode: "",
    }));
    setSiteFormDisplay("none");
  };
  const resetNewChampionForm = () => {
    setNewChampionFormData((values: any) => ({
      ...values,
      firstName: "",
      lastName: "",
      emailAddress: "",
    }));
    setNewChampionFormDisplay("none");
  };
  const handleSitesTextFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    try {
      resetSiteForm();
      setSiteFormDisplay("none");
    } catch (ex) {}
  };
  const validateName = () => {
    return userId.length > 0;
  };
  const validateSiteOrPhysician = () => {
    var isSiteValid = false;
    if (validateSite() && siteFormDisplay === "none") {
      isSiteValid = true;
    } else if (
      validateSite() &&
      siteFormDisplay === "block" &&
      validateSiteDetails()
    ) {
      isSiteValid = true;
    }
    return physicianGroupId.length > 0 || isSiteValid;
  };

  function handleActionResponse(
    response: any,
    successMessage: string,
    errorMessage: string
  ) {
    try {
      //Status 200 and data is true assign sites to champion
      if (response.status === 200 && response.data === true) {
        adminContext.onRecordSave(true, successMessage);
        props.onsuccess();
      } //Status 200, and data is false. Sites have already been assigned to the champion.
      else if (response.status === 200 && response.data === false) {
        adminContext.onError(true, errorMessage);
      } else {
        adminContext.onError(true, errorMessage);
      }
    } catch (ex) {
      // console.log(ex);
    }
  }

  async function sendEmailOnSubmit() {
    waitIndicatorLoaderRef.current?.show();
    const user_info: IACEPTokenInfo = await getUserInfoFromStorage();
    if (formData.siteId === "notFound") {
      const newSiteRequestData: INewSiteRequestData = {
        toEmail: "", //not passed it will be handled in the backend
        fromEmail: user_info.email,
        ccEmail: "", //not passed it will be handled in the backend
        acepNumber: user_info.sub, //acepNumber
        givenName: user_info.given_name,
        familyName: user_info.family_name,
        siteId: formData.siteId,
        siteName: formData.siteName,
        isEDPartOfLargeGroup: formData.isEDPartOfLargeGroup,
        physicianGroupName: formData.physicianGroupName,
        country: formData.country,
        addressLine1: formData.addressLine1,
        addressLine2: formData.addressLine2,
        city: formData.city,
        state: formData.state,
        zipCode: formData.zipCode,
      };
      try {
        const response = await SendEmailForNewSite(newSiteRequestData);
        if (response?.data) {
          adminContext.onRecordSave(
            true,
            "Your request was successfully submitted."
          );
          props.onsuccess();
        } else {
          adminContext.onError(
            true,
            `An unexpected error has occurred, please <a target="_blank" rel="noopener noreferrer" style={{ color: "#1976D2" }} href=${process.env.REACT_APP_ENROLL_SITE_CONTACT_US_URL}>contact us</a>.`
          );
        }
        resetSearchQuery();
        resetSiteForm();
        resetForm();
        waitIndicatorLoaderRef.current?.hide();
      } catch (error) {
        console.error("API Error:", error);
      }
    } else if (userId === "notFound") {
      const newChampionEmailData: INewChmapionEmailData = {
        toEmail: newChampionFormData.emailAddress,
        fromEmail: user_info.email,
        inviteeFirstName: newChampionFormData.firstName,
        invitorsName: user_info.given_name,
      };
      try {
        const response = await sendEmailForNewChampion(newChampionEmailData);
        if (response?.data) {
          adminContext.onRecordSave(true, "Email invitation send.");
          props.onsuccess();
        } else {
          adminContext.onError(
            true,
            `An unexpected error has occurred, please contact admin.`
          );
        }
        resetNameSearchQuery();
        resetNewChampionForm();
        resetForm();
        waitIndicatorLoaderRef.current?.hide();
      } catch (error) {
        console.error("API Error:", error);
      }
    }
  }

  const handleChangeRadioButton = async (event: {
    target: {
      value: any;
      id: any;
      name: any;
    };
  }) => {
    const value = event.target.value;
    let id = event.target.id;
    //Id is combination of ${name}-${options.Id}. Extract id from event.target.id.
    if (id && event.target.id.split("-").length > 1) {
      id = await event.target.id.split("-")[1];
    }
    setSelectedRedioValue({ id: id, value: value });
    if (id === "1") {
      setPhysicianAssigndisplay("block");
      setSitesAssigndisplay("none");
      resetSearchQuery();
      setFormData((values: any) => ({ ...values, siteId: "", siteName: "" }));
      setSiteFormDisplay("none");
    } else {
      setSitesAssigndisplay("block");
      setPhysicianAssigndisplay("none");
      setPhysicianGroupId("");
      setPhysicianSearchQuery("");
    }
  };

  const userSearchChangeHandler = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      setNewChampionFormDisplay("none");
    } catch (ex) {
      // console.log(ex);
    }
  };

  const filterPhysicianData = (query: any, data: any[]) => {
    if (!query) {
      return data;
    } else {
      return data.filter((d) =>
        d.name.toLowerCase().includes(query.toLowerCase())
      );
    }
  };
  const handlePhysicianTextFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const physicianSearchQuery = e.target?.value ? e.target?.value : "";
      let physicianDataFiltered = filterPhysicianData(
        physicianSearchQuery,
        appPhysicianGroupData.appData
      );
      setPhysicianDataFiltered(physicianDataFiltered);
      if (physicianSearchQuery) {
        setPhysiciandisplay("block");
        setPhysicianSearchQuery(e.target.value);
      } else {
        setPhysicianSearchQuery("");
        setPhysiciandisplay("none");
      }
      if (physicianDataFiltered.length === 0) {
        setPhysiciandisplay("none");
        setPhysicianGroupId("");
      }
    } catch (ex) {
      // console.log(ex);
    }
  };
  const SetPhysicianValueHandler = (e: any) => {
    try {
      setPhysicianSearchQuery(e.target.innerText);
      setPhysiciandisplay("none");
      setPhysicianGroupId(e.target.id);
      resetSearchQuery();
      setFormData((values: any) => ({ ...values, siteId: "", siteName: "" }));
    } catch (ex) {
      // console.log(ex);
    }
  };

  const setNameValueHandler = (e: any) => {
    try {
      setUserId(e.userId);
      setFormData((values: any) => ({ ...values, siteId: e.id, siteName: "" }));
      if (e.userId === "notFound") {
        setNewChampionFormDisplay("block");
        setNewChampionFormData((values: any) => ({
          ...values,
          firstName: "",
          lastName: "",
          emailAddress: "",
        }));
        setPhysicianAssigndisplay("none");
        setSitesAssigndisplay("none");
        setSiteFormDisplay("none");
        setPhysicianGroupId("");
        setPhysicianSearchQuery("");
        setPhysiciandisplay("none");
        setSelectedRedioValue({ id: "", value: "" });
        setSearchQuery("");
        //resetNameSearchQuery();
      } else {
        setNewChampionFormDisplay("none");
        resetNewChampionForm();
      }
    } catch (ex) {
      // console.log(ex);
    }
  };
  const inputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setNewChampionFormData((values: any) => ({ ...values, [name]: value }));
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="add-champions"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle
          style={{
            fontWeight: 600,
            fontSize: "32px",
            padding: "55px 0px 25px 55px",
          }}
        >
          Add Champion
        </DialogTitle>
        <DialogContent style={{ padding: "0px 55px 0px 55px" }}>
          <Grid item xs={12} sm={12}>
            <TypeAheadUserList
              label="Name"
              name="user"
              options={[]} //empty Array becuase we are not showing any data in the list on filter change
              value={SearchQuery}
              setValueHandler={setNameValueHandler}
              handleTextFieldChange={userSearchChangeHandler}
              notFoundMessage="I do NOT see who I'm looking for"
              ref={typeAheadUserListRef}
            />
          </Grid>
          {/* <Grid container style={{ marginTop: 20, display: newChampionFormDisplay }}>
            <Grid
              container
              style={{ marginTop: 20 }}
            >
              <Grid
                item
                xs={5.75}
                sm={5.75}
              >
                <LabelTextField
                  displayName="First Name"
                  name="firstName"
                  onTextChange={(e) => inputChangeHandler(e)}
                  value={newChampionFormData.firstName}
                />
              </Grid>
              <Grid item xs={0.5} sm={0.5}></Grid>
              <Grid item xs={5.75} sm={5.75}>
                <LabelTextField
                  displayName="Last Name"
                  name="lastName"
                  onTextChange={(e) => inputChangeHandler(e)}
                  value={newChampionFormData.lastName}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} marginTop={"20px"}>
              <LabelTextField
                displayName="Email"
                name="emailAddress"
                onTextChange={(e) => inputChangeHandler(e)}
                value={newChampionFormData.emailAddress}
              />
            </Grid>
          </Grid> */}
          <NewUserForm
            newChampionFormDisplay={newChampionFormDisplay}
            newChampionFormData={newChampionFormData}
            setNewChampionFormData={setNewChampionFormData}
            OnSubmitHandler={sendEmailOnSubmit} // Pass the function as a prop
            handleClose={handleClose}
          />
          <Grid
            item
            xs={12}
            sm={12}
            style={{
              marginTop: 20,
              display: newChampionFormDisplay === "block" ? "none" : "block",
            }}
          >
            <RadioButtonGroup
              name="rdbSitePhysicianGroup"
              label=""
              value={selectedRedioValue.value}
              options={sitePhysicianGroup}
              onChange={handleChangeRadioButton}
              variant="inline"
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            style={{ marginTop: 20, display: PhysicianAssigndisplay }}
          >
            <LabelTextField
              displayName="Physician Group"
              name="physician_group"
              onTextChange={handlePhysicianTextFieldChange}
              value={PhysicianSearchQuery}
            />
            <div
              style={{
                padding: 3,
                display: Physiciandisplay,
                border: "1px solid #cccc",
                borderRadius: 20,
              }}
            >
              <ul
                style={{
                  listStyle: "none",
                  overflowY: "scroll",
                  overflowX: "hidden",
                  height: "104px",
                  scrollbarWidth: "thin",
                  margin: "6px 0px 6px 0px",
                }}
              >
                {physicianDataFiltered.map((physicianDataFiltered: any) => (
                  <li
                    className="text"
                    style={{
                      padding: 5,
                      justifyContent: "normal",
                      fontSize: 16,
                      fontWeight: 400,
                      color: "#000000",
                      margin: 1,
                      width: "100%",
                      borderWidth: "10px",
                      cursor: "pointer",
                    }}
                    key={physicianDataFiltered.id}
                  >
                    <div
                      id={physicianDataFiltered.id}
                      onClick={(e) => SetPhysicianValueHandler(e)}
                      style={{ color: "#000000" }}
                    >
                      {physicianDataFiltered.name}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            style={{ marginTop: 20, display: SitesAssigndisplay }}
          >
            <TypeAheadList
              label="Site"
              name="sites"
              options={appSitesData.appData}
              value={formData.siteName}
              setValueHandler={setSiteValueHandler}
              handleTextFieldChange={handleSitesTextFieldChange}
              notFoundMessage="I do NOT see my ED site"
              ref={typeAheadListRef}
            />
          </Grid>
          <SiteForm
            style={{ display: siteFormDisplay }}
            formData={formData}
            setFormData={setFormData}
          ></SiteForm>
        </DialogContent>
        <DialogActions
          style={{
            padding: "55px 55px 55px 0px",
            display: newChampionFormDisplay === "block" ? "none" : "",
          }}
        >
          <RoundedButton
            name="cancel"
            text="Cancel"
            buttonType="secondary"
            onClick={handleClose}
          />
          <RoundedButton
            name="submit"
            text="Submit"
            disabled={!isFormValid()}
            onClick={OnSubmitHandler}
          />
        </DialogActions>
        <WaitIndicator ref={waitIndicatorLoaderRef} />
      </Dialog>
    </div>
  );
});
export default ManageChampionsFormDialog;
