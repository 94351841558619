import React from 'react';
import { Grid, DialogActions } from '@mui/material';
import LabelTextField from "../atoms/formElements/LabelTextField";
import RoundedButton from "../atoms/formElements/RoundedButton";

interface ChampionFormProps {
  newChampionFormDisplay: string;
  newChampionFormData: {
    firstName: string;
    lastName: string;
    emailAddress: string;
  };
  setNewChampionFormData: React.Dispatch<React.SetStateAction<any>>;
  handleClose: () => void;
  OnSubmitHandler: () => void;
  
}

const NewUserForm: React.FC<ChampionFormProps> = ({
  newChampionFormDisplay,
  newChampionFormData,
  setNewChampionFormData,
  handleClose,
  OnSubmitHandler,
  
}) => {
    // Define the inputChangeHandler within the component
    const inputChangeHandler = (e: { target: { name: any; value: any; }; }) => {
      const { name, value } = e.target;
      setNewChampionFormData((prevData: any) => ({
        ...prevData,
        [name]: value
      }));
    };


  
    const validateNewChampionForm = () => {       
        return newChampionFormData.firstName.trim() !== "" && newChampionFormData.lastName.trim() !== "" && newChampionFormData.emailAddress.trim() !== "";
      
    }

    return (
      <Grid container style={{ marginTop: 20, display: newChampionFormDisplay }}>
        <Grid container style={{ marginTop: 20 }}>
          <Grid item xs={5.75} sm={5.75}>
            <LabelTextField
              displayName="First Name"
              name="firstName"
              onTextChange={inputChangeHandler}
              value={newChampionFormData.firstName}
            />
          </Grid>
          <Grid item xs={0.5} sm={0.5}></Grid>
          <Grid item xs={5.75} sm={5.75}>
            <LabelTextField
              displayName="Last Name"
              name="lastName"
              onTextChange={inputChangeHandler}
              value={newChampionFormData.lastName}
            />
          </Grid>
        </Grid>
  
        <Grid item xs={12} sm={12} marginTop={"20px"}>
          <LabelTextField
            displayName="Email"
            name="emailAddress"
            onTextChange={inputChangeHandler}
            value={newChampionFormData.emailAddress}
          />
        </Grid>
  
        <DialogActions style={{ padding: "55px 55px 55px 0px"}}>
          <RoundedButton
            name="cancel"
            text="Cancel"
            buttonType="secondary"
            onClick={handleClose}
          />
          <RoundedButton
            name="submit"
            text="Submit"
            disabled = {!validateNewChampionForm()}
            onClick={OnSubmitHandler}
          />
        </DialogActions>
      </Grid>
    );
  };
  
  export default NewUserForm;
